import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import swal from "sweetalert2";
import { Router } from "@angular/router";

// import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { Subject } from "rxjs";
import { apiUrl } from "../global/global";
import { DataSharingService } from "./data.share.service";
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AdminService {
  apiEndpoint: string = "";
  showLoader: boolean = true;
  access_token: any;
  // icon = icons;
  // menu = menu;
  // btnText = BtnText;
  // generalModel = new generalModel();
  // Permission = permission;
  public _albums = [];
  onlineMessage:any='online';
  constructor(private http: HttpClient, private router: Router,private dataSharingService: DataSharingService) {
    this.apiEndpoint = environment.apiUrl;
    this.access_token = localStorage.getItem("adminToken");
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getNoloader(url, param) {
    this.showLoader = false
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDatas(url) {
    return this.http.get<any>(this.apiEndpoint + url);
  }
  getDataNoloader(url) {
    this.showLoader = false
    return this.http.get<any>(this.apiEndpoint + url);
  }

  getDetailByidParams(data, url, param = {}) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {
      params: param,
    });
  }
  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail1(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDetailByid(data, url) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {});
  }
  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, param);
  }
  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }

  httpOptions1 = {
    headers: new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    ),
  };
  postDataNew(url, value) {
    return this.http.post<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }
  putDataNew(url, value) {
    return this.http.put<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }
  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }
  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  // deleteData(url, value) {
  //   return this.http.delete<any>(this.apiEndpoint +url, value);
  // }

  deleteData(url, param) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: param,
    };
    return this.http.delete<any>(this.apiEndpoint + url, options);
  }

  showAlert(title: string, text: string) {
    swal.fire({
      title: '<figure><img src="../../assets/images/sucess.svg"></figure>',
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Okay",
      confirmButtonColor: "#2b3991",
      timer: 1500,
    });
  }

  showError(title: string, text: string, confirmButtonText: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
   
      confirmButtonColor: "#2b3991",
      timer: 8000,
    });
  }

  errorAlert(text, status) {
    this.createOnline$().subscribe((isOnline) => {
      console.log(isOnline);
      if (isOnline) {
        this.onlineMessage = 'online';
      } else {
        this.onlineMessage ='No internet, please enable wifi in your device setting and try again. ';
      } 
    });
    swal
      .fire({
        title: this.onlineMessage=='online'?"We thought you'd gone away":"Connection lost",
        text: this.onlineMessage=='online'?text:this.onlineMessage,
      })
      .then((result) => {
        if (status == 408 || status == 401) {

          // this.router.navigate(["/buy-plan"]);
          this.dataSharingService.clear();
          this.router.navigate(["/login"]);
        } else if (status) {
          this.dataSharingService.clear();
          this.router.navigate(["/login"]);
        }
      });
  }

  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) {
    this.loaderSubject.next(value);
  }

  logOut() {
    swal
    .fire({
      icon: "warning",
      text: "Are you sure you want to logout ?",
      showCancelButton: true,
      confirmButtonColor: "#2b3991",
      cancelButtonColor: "#939393",
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.value) {
        const data = {
          deviceToken: localStorage.getItem("deviceToken") ? localStorage.getItem("deviceToken") :'0123456789123456',
        }
        this.putData(apiUrl._logOut,data).subscribe((res)=>{
          this.dataSharingService.clear();
          this.router.navigate(["/login"]);
        })
      }
    });
  }

  expiedPlan() {
    swal
    .fire({
      icon: "warning",
      text: "Your plan has expired. Please purchase a license to continue using Tax Canary.",
      showCancelButton: true,
      confirmButtonColor: "#2b3991",
      confirmButtonText: "Buy plan",
    })
    .then((result) => {
      if (result.value) {
        this.router.navigate(["/plan/buy"]);
      } else {
        this.dataSharingService.clear();
        this.router.navigate(["/login"]);
      }
    });
  }


  // setdata(data) {
  //   for (let x of tabArray) {
  //     let array1 = {}
  //     for (let y of x.value) {
  //       array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
  //     }
  //     permission[x.name] = array1;
  //   }
  //   permission['status'] = "updated";
  //   return true;
  // }
  createOnline$() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
