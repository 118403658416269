import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class DeeplinkService {
    private platformId: any;
    constructor(@Inject(PLATFORM_ID) platformId: Object) { 
        this.platformId = platformId;
    }


    deeplink(url) {
        if (isPlatformBrowser(this.platformId)){
            console.log(url)
            let ua = navigator.userAgent.toLowerCase();
            let isAndroid = ua.indexOf("android") > -1; // android check
            let isIphone = ua.indexOf("iphone") > -1; // ios check
            if(isAndroid || isIphone) {
                let app = {
                    launchApp: function () {
                        setTimeout(function () {
                            //  console.log(url)
                            window.location.href = url;
                        }, 25);
                        window.location.href = url; //which page to open(now from mobile, check its authorization)
                    },
                    openWebApp: function () {
                        window.location.href = url;
                    }
                    // console.log(window.location.href)
                };
                app.launchApp();
                window.location.href == url ? 'succes' : 'fail';
            }
        }
    }
}