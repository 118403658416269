// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	 //apiUrl: 'https://dev-api.taxcanary.com/',
  apiUrl: "https://stage-api.taxcanary.com/",
  imgUrl: 'https://taxcanary-dev.s3.amazonaws.com/users/',
  imgUrlcm: 'https://taxcanary-dev.s3.amazonaws.com/',
  production: false,
  firebase: {
    apiKey: "AIzaSyCW28l1UgSzHuty8SwA0Je2Q9xBjbB70LM",
    authDomain: "tax-canery.firebaseapp.com",
    projectId: "tax-canery",
    storageBucket: "tax-canery.appspot.com",
    messagingSenderId: "747169763404",
    appId: "1:747169763404:web:4aeef9f99b4dc1099de4e9",
    measurementId: "G-BFH3CX84VD"
  },
};


/*6.0",
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
