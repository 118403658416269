import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { apiUrl } from "src/app/global/global";
import { AdminService } from "src/app/services/admin.service";
import { DataSharingService } from "src/app/services/data.share.service";
// import { SIDE_NAV_LIST } from '../../services/constants';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menu_items: any[] = [];
  selectedAvtar: string = "";
  localData;
  sharePageUrl:any;
  toggle: boolean = false;
  private platformId: any;
  constructor(public dataSharingService: DataSharingService, private api: AdminService, @Inject(PLATFORM_ID) platformId: Object) { 
    this.platformId = platformId;
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    this.api.getDatas(apiUrl._user).subscribe((res) => {
      this.localData = res.data;
    })
  }

  sideToggle() {
    let body = document.getElementsByTagName('body')[0];
    if (!this.toggle) {
      body.classList.add("sidebar-mini");
      this.toggle = true;
    } else {
      body.classList.remove("sidebar-mini");
      this.toggle = false;
    }
  }

  toggleMob() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("sidebar-mobile");
    this.toggle = false;
  }

  toShow() {
    if (document.body.className == 'sidebar-mobile') {
      return true;
    } else {
      return false;
    }
  }
  shareOnFB() {
    if (isPlatformBrowser(this.platformId)){ 
      this.sharePageUrl = window.location.href
      //this.sharePageUrl = "https://dev-web.taxcanary.com/"
    }
  }


}